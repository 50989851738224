import { Link } from '@remix-run/react'
import { LazyImage } from '~/components/LazyImage'
import { Button } from '~/components/ui/button'

export default function IndexRoute() {
  const images = [
    // 'https://solidten-storage.s3.amazonaws.com/08-23/aaf4c1aa-c36c-4271-a0f2-dab24dc48a2f/ef2e00a1.png',
    // 'https://solidten-storage.s3.amazonaws.com/08-23/16000996-7f9e-4224-9340-6cd8b29ced59/821acdbf.png',
    // 'https://solidten-storage.s3.amazonaws.com/08-23/0facc67a-9cc6-4320-a9b0-f5d1f780727e/05b2fde3.png',
    // 'https://solidten-storage.s3.amazonaws.com/08-23/d851765e-727a-4fec-9f39-27c89af4001e/28d2018d.png',
    // 'https://solidten-storage.s3.amazonaws.com/08-23/a221c320-789a-4e94-82e3-51229a447a5c/7e533fcb.png',
    // 'https://solidten-storage.s3.amazonaws.com/08-23/c4b0e4fa-4830-4d8c-9d78-bc16c1058c2d/0ccb2735.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/c619c5f6-bfbf-445e-8573-b7d17d890174/2bde4e6f.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/3d71ccf7-a8e4-4f3c-94c5-4f3360d984f3/6cf370f0.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/b223ebce-1c6a-415a-a7fd-682fcd71f503/510a6a50.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/bec8aa7e-7355-4e62-a32a-9d2ef45ba889/1fb09e1b.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/a9592733-a010-4018-af39-d0ccb2ee804b/f930f978.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/090d6ede-b2d8-4a53-b0d0-0b058dd89ca0/2488a838.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/a7f2e99e-934b-45e4-90e6-89aac578e13b/6aef1b5a.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/fee39076-33f3-45e1-b5a2-d642d5c1caed/afb238b1.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/76ccb34c-dc77-4ebe-abb5-2be200ac2e34/c6d061f1.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/be534d6e-da57-433d-9320-60dbc7df23a6/db3c1a1f.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/bbfe5c44-12fd-4a5a-a5cf-8f4d717dcb91/03ac2748.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/04a76885-6d01-4411-b198-09dda5378a92/3377afd0.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/4c434e42-dc82-42bc-b9da-b9299874548a/cad7b575.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/369ca45a-ae19-4e2d-a768-2dac7bd9c861/53913752.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/6e5394a5-c3e7-4739-a356-c93ce751059d/2900e9bd.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/d6e08516-8894-42ed-a9c2-ff9a6a30a832/2e4b3996.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/f3ca3dd2-238b-4b53-903c-3bf5737c25f1/f0fae2dc.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/e29cec76-d96a-4aed-8216-1653de365215/f4cafb32.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/8ea13f2a-e28c-4df6-8568-36f5f2ab7ef9/5d0d2dca.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/b084108c-6d5e-49e5-adb1-abc81f522300/5e2cdc4b.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/97a04e52-ba72-4abe-90d5-67f74b637a26/0cf19e40.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/c6367027-7014-44f6-97b3-33db44b9f3be/f50d82e9.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/32515fc5-2bdb-4991-b241-593392fafdb7/80b1d9e8.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/d0b200ba-31d0-415e-b9a8-434430adb4a5/1a95ae66.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/d4d44649-b098-4b85-9be5-bfbbff4d34b3/9c85f193.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/42ec1d5c-e61b-42e4-920b-36360bff75cf/89a50b1a.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/7d28705a-eccc-4b11-b860-2e93f4acdcca/435f00d7.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/fd56a5b4-4c36-4ae2-85c5-d08ae5538691/bb9d902b.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/bb67f2b8-5f68-4912-936c-5963784f08cd/435783d0.png',
    'https://solidten-storage.s3.amazonaws.com/08-23/92e8c115-e07f-4f02-992f-2616fcd9a972/2f7b3e00.png',
  ]

  return (
    <>
      {/* marketing words and button to create avatar */}
      <div className="mx-auto block w-5/6 py-4 text-center text-neutral-700">
        <div className="py-8 text-4xl font-semibold">
          Transform a <span className="text-5xl text-green-500">single</span> photo into
          personalized AI art in <span className="text-5xl text-red-500">seconds</span>. 🚀
        </div>
        <div className="mx-auto w-5/6 py-1">
          <p className="py-1">
            SolidTen's <strong>Instant Dreambooth</strong> makes training a custom AI art character
            1000x faster than it used to be. Bring your digital persona to life without the need to
            wait for hours for the AI to train. Iterate quickly without fear of costly mistakes.
          </p>
        </div>
        <div className="py-8">
          <p className="py-2 text-lg">
            Create diverse and stunning personalized AI art instantly in any style - currently at no
            cost.
          </p>
          <Button className="my-1 text-lg font-bold" asChild>
            <Link to="/create" prefetch="intent">
              Create Personalized AI Art
            </Link>
          </Button>
        </div>
      </div>

      {/* invitation to checkout examples */}
      <div className="text-center text-3xl font-semibold text-neutral-700">
        Here are examples of personalized AI art made with SolidTen.
      </div>

      {/* example images */}
      <div className="flex justify-center py-4">
        <div className="grid grid-cols-2 gap-1.5 px-3 lg:grid-cols-3 lg:px-5 xl:grid-cols-6 2xl:px-10">
          {images.map((url, index) => (
            <LazyImage
              src={url}
              alt="example"
              width={512}
              height={640}
              key={url}
              loading="eager"
              className="rounded-xl hover:ring-1 hover:ring-primary"
            />
          ))}
        </div>
      </div>

      {/* button to create avatar */}
      <div className="mx-auto block w-2/3 py-8 text-center text-neutral-700">
        <p className="py-2 text-lg">
          Ready to unveil the inherent <em>solid 10</em> in yourself or your loved ones?
        </p>
        <Button className="my-1 text-lg font-bold" asChild>
          <Link to="/create" prefetch="intent">
            Yes, I am ready!
          </Link>
        </Button>
      </div>

      {/* <div className="text-center text-md font-semibold text-neutral-700 py-8 w-2/3 mx-auto">
        You might think that we are just like the other hundred AI apps you find on the internet. We
        are confident that our unique blend of proprietary innovations sets us apart from the rest.
        We invite you to experience the difference for yourself.
      </div> */}
    </>
  )
}
